import axios from 'axios'
import {config} from '../../Constants'

export const stallApi = {
    getStalls,
    getStall,
    modifyStall,
    updateStallPosition,
    updateStallPause,
    saveStall,
    deleteStall,
    getUserExtrasMe,
    saveUserExtrasMe
}

function getStalls(token) {
    const headers = token ? {
        'Content-type': 'application/json',
        'Authorization': bearerAuth(token)
    } : {}
    return instance.get('/api/stalls', {
        headers: headers
    })
}

function getStall(id) {
    return instance.get(`/api/stalls/${id}`)
}

async function modifyStall(id, description, token) {
    return instance.put(`/api/stalls/${id}`, {id: id, description: description}, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

async function updateStallPosition(id, lat, lon, token) {
    console.log(id +" " + lat + " " + lon);
    return instance.put(`/api/stalls/${id}/position`, {lat: lat, lon: lon}, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

async function updateStallPause(id, paused, token) {
    return instance.post(`/api/stalls/${id}/pause`, {paused: paused}, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

async function saveStall(stall, token) {
    return instance.post('/api/stalls', stall, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

async function deleteStall(id, token) {
    return await instance.delete(`/api/stalls/${id}`, {
        headers: {'Authorization': bearerAuth(token)}
    })
}

function getUserExtrasMe(token) {
    return instance.get(`/api/userextras/me`, {
        headers: {'Authorization': bearerAuth(token)}
    })
}

function saveUserExtrasMe(token, userExtra) {
    return instance.post(`/api/userextras/me`, userExtra, {
        headers: {'Authorization': bearerAuth(token)}
    })
}

// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    if (error.response.status === 404) {
        return {status: error.response.status};
    }
    return Promise.reject(error.response);
});

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}