import React, {useState} from 'react'
import {stallApi} from '../misc/StallApi'
import {Button, Form, Segment} from 'semantic-ui-react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {useKeycloak} from "@react-keycloak/web";
import {useHistory, useParams} from "react-router-dom";
import {useStoreActions, useStoreState} from "easy-peasy";

function Stall() {
    const loadStalls = useStoreActions((actions) => actions.loadStalls);
    let {editStallId} = useParams();
    const stalls = useStoreState((actions) => actions.stalls);
    const handleSubmit = async (description) => {

        if (editMode) {
            await stallApi.modifyStall(currentStall.id, description, keycloak.token)
        } else {
            await stallApi.saveStall({
                description: description,
                lat: lat,
                lon: lon
            }, keycloak.token);
            await loadStalls(keycloak.token);
            localStorage.setItem('lastKnownMapCenter', JSON.stringify({lat: lat, lng: lon}));
            localStorage.setItem('lastKnownZoomLevel', JSON.stringify(13));

        }
        history.push("/");

    };
    let history = useHistory();
    const {keycloak} = useKeycloak()
    const [lat, setLat] = useState({});
    const [lon, setLon] = useState({});

    let currentStall = stalls.find(stall => stall.id + "" === editStallId);
    let editMode = currentStall !== undefined;

    if (!editMode) {
        currentStall = {};
        currentStall.description = '';
    }
    const [description, setDescription] = useState(currentStall.description);

    function getGooglePlacesAutocomplete(editMode) {
        return !editMode && <GooglePlacesAutocomplete autocompletionRequest={{
            componentRestrictions: {
                country: ['de'],
            }
        }}
                                                      selectProps={{
                                                          placeholder: "Adresse des Standes eingeben",
                                                          onChange: (place) => {
                                                              geocodeByPlaceId(place.value.place_id).then(
                                                                  (value => {
                                                                      setLat(value[0].geometry.location.lat());
                                                                      setLon(value[0].geometry.location.lng());
                                                                  }))
                                                          }
                                                      }}/>;
    }

    return (<Segment onSubmit={() => handleSubmit(description)}>
            <Form>
                <Form.TextArea
                    placeholder='Bechreibung'
                    name='description'
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    maxlength="500"
                />
                {getGooglePlacesAutocomplete(editMode)}
                <Button type='submit' style={{"margin-top": "1rem"}}>{editMode ? "Stand speichern" : "Stand eintragen"}</Button>
            </Form>
        </Segment>
    )
}

export default Stall;