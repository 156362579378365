import React from 'react'
import {Segment} from 'semantic-ui-react'

function Howto() {
    function getContactInfo() {
        if(localStorage.getItem("contact-email")){
            return (<p>Bei Fragen zum <b>{localStorage.getItem("event-name")}</b> kannst du dich an <a href={"mailto:" + localStorage.getItem("contact-email")}>{localStorage.getItem("contact-email")}</a>{} wenden. Bei <b>technischen Problemen</b> schreibe gerne an: <a href="mailto:support@flohmarkttag.de">support@flohmarkttag.de</a></p>);
        }
    }

    return (<Segment>
            <p>Am Flohmarkttag gibt es in deiner Nachbarschaft Flohmarktstände auf privaten Grundstücken. Auf unserer Karte siehst Du wer schon mitmacht und was in etwa verkauft wird.</p>
            <p>Wenn du mitmachen möchtest, stelle einfach ein paar Tische in deinen Vorgarten (nicht auf die Straße) und verkaufe entspannt, ohne Standgebühr, mit netten Nachbarn und Freunden zusammen von Zuhause.</p>
            <p>Du kannst dich auf dieser Seite registrieren, damit wir deinen Stand auf der Karte anzeigen. Falls die automatisch gesetzte Markierung in der Karte nicht ganz stimmt, kannst du sie nachträglich an die richtige Stelle in deinem Garten verschieben. Wenn du deine Adresse in der Beschreibung nennst, können die Besucher dich noch besser finden.</p>
            <p>Es hilft dir und allen Käufern und Verkäufern, wenn du Werbung in sozialen Medien oder auch mit einem echten Schild an deinem Gartenzaun machst.</p>
            {getContactInfo()}
            <p>Wir wünschen viel Spaß beim Ein-/Verkaufen und natürlich gute Geschäfte auf beiden Seiten des Tapeziertisches!</p>
            <p><small>PS: Auch wenn dein Stand in deinem Garten steht, musst du dich an die üblichen Regeln zur Coronabekämpfung, den Jugendschutz, die Hygienevorschriften usw. halten</small></p>
        </Segment>
    )
}

export default Howto;