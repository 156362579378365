import React from 'react'
import {ReactKeycloakProvider} from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import {NavLink, Route, Switch, useHistory} from 'react-router-dom'
import Home from './components/home/Home'
import Navbar from './components/misc/Navbar'
import PrivateRoute from './components/misc/PrivateRoute'
import {Container, Dimmer, Header, Icon} from 'semantic-ui-react'
import {config} from './Constants'

import Stall from "./components/home/Stall";
import store from "./store";
import {StoreProvider} from "easy-peasy";
import Impressum from "./components/home/Impressum";
import Redirect from "react-router-dom/es/Redirect";
import {setCity} from "./cities";
import Howto from "./components/home/Howto";
import {getMarketDay, marketDays} from "./marketDays";

function App() {

    const keycloak = new Keycloak({
        url: `${config.url.KEYCLOAK_BASE_URL}/auth`,
        realm: "flohmarkttag.de",
        clientId: "flohmarkttag"
    })
    const initOptions = {pkceMethod: 'S256'}

    let history = useHistory();
    const handleOnEvent = async (event, error) => {
        if (event === 'onAuthSuccess') {
            if (keycloak.authenticated) {
                const routeBeforeLogin = localStorage.getItem('routeBeforeLogin');
                if (routeBeforeLogin && routeBeforeLogin !== '') {
                    history.push(routeBeforeLogin);
                    localStorage.setItem('routeBeforeLogin', '');
                }
            }
        }
    }

    return (
        <StoreProvider store={store}>
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={initOptions}
                onEvent={(event, error) => handleOnEvent(event, error)}
            >
                <Navbar/>
                <Switch>
                    <Route path='/' exact component={() => {
                        if(!getMarketDay(localStorage.getItem('city'))){
                            setCity("BOENNINGSTEDT");
                        }
                        return <Home/>;
                    }}/>
                    <Route path='/home' component={Home}/>
                    {marketDays.map(marketDay => createRoute(marketDay))}
                    <PrivateRoute path='/newStall/:editStallId' component={Stall}/>
                    <Route path='/howto' component={Howto}/>
                    <Route path='/impressum' component={Impressum}/>
                    <Redirect to='/'/>
                </Switch>
                <Container className="footer" style={{"text-align": "center", "width": "100%"}}><NavLink to="/impressum"
                                                                                                         activeClassName="selected">
                    Impressum/Datenschutzerklärung
                </NavLink>
                </Container>
            </ReactKeycloakProvider>
        </StoreProvider>
    )
}

function createRoute(marketDay){
    console.log(marketDay)
    return <Route path={marketDay.url} component={() => {
        setCity(marketDay.id);
        return <Home/>;
    }}/>
}

export default App
