const prod = {
  url: {
    KEYCLOAK_BASE_URL: "https://login.flohmarkttag.de",
    API_BASE_URL: 'https://flohmarkttag.de',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://avatars.dicebear.com/api'
  }
}

const dev = {
  url: {
    KEYCLOAK_BASE_URL: "https://login.flohmarkttag.de",
    // API_BASE_URL: 'https://flohmarkttag.de',
    API_BASE_URL: 'http://localhost:9080',
    OMDB_BASE_URL: 'https://www.omdbapi.com',
    AVATARS_DICEBEAR_URL: 'https://avatars.dicebear.com/api'
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod