import {getMarketDay} from "./marketDays";

export function setCity(marketDayId) {
    let marketDay = getMarketDay(marketDayId);
    console.log("setCity " + marketDay.name)
    localStorage.setItem('lastKnownMapCenter', JSON.stringify({
        lat: marketDay.center.lat,
        lng: marketDay.center.lng,
    }));
    localStorage.setItem('lastKnownZoomLevel', JSON.stringify(marketDay.center.zoomLevel));
    localStorage.setItem('mapBannerText', JSON.stringify(marketDay.bannerText));
    localStorage.setItem('city', marketDayId);
    localStorage.setItem('contact-email', marketDay.contactEmail);
    localStorage.setItem('event-name', marketDay.name);
}

// export function setBoenningstedt() {
//     localStorage.setItem('lastKnownMapCenter', JSON.stringify({
//         lat: "53.66403",
//         lng: "9.90552",
//     }));
//     localStorage.setItem('lastKnownZoomLevel', JSON.stringify(14));
//     localStorage.setItem('mapBannerText', JSON.stringify("In Bönningstedt am 17.09.2023 von 10 - 16 Uhr"));
//     localStorage.setItem('city', 'BOENNINGSTEDT');
//     localStorage.setItem('contact-email', 'Flohmarkttag@rainer-buemmer.de');
//     localStorage.setItem('event-name', 'Flohmarkttag Bönningstedt');
// }
//
// export function setEidelstedt() {
//     localStorage.setItem('lastKnownMapCenter', JSON.stringify({
//         lat: "53.6183",
//         lng: "9.8950",
//     }));
//     localStorage.setItem('lastKnownZoomLevel', JSON.stringify(16));
//     localStorage.setItem('mapBannerText', JSON.stringify("In Eidelstedt am 18.06.2022 von 11 - 15 Uhr"));
//     localStorage.setItem('city', 'EIDELSTEDT');
//     localStorage.setItem('contact-email', 'Flohmarkttag-Eidelstedt@web.de');
//     localStorage.setItem('event-name', 'Flohmarkttag Eidelstedt');
// }
