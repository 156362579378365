import React, {Fragment, useEffect} from 'react'
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useStoreActions, useStoreState} from "easy-peasy";
import {useKeycloak} from "@react-keycloak/web";
import {Divider, Header, Icon} from "semantic-ui-react";
import {stallApi} from "../misc/StallApi";
import {isAdmin} from "../misc/Helpers";
import {useHistory} from "react-router-dom";
import "./StandMap.css";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";
import {getMarketDay, getMarketDayBoundary} from "../../marketDays";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [17, 46]
});

let PausedIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [17, 46],
    className: "paused"
});

L.Marker.prototype.options.icon = DefaultIcon;

function getCurrentMarketDayBoundary() {
    const geoJson = getMarketDayBoundary(localStorage.getItem('city'));
    const glookup = new GeoJsonGeometriesLookup(geoJson);
    return glookup;

}

const StandMap = () => {
    const loadStalls = useStoreActions((actions) => actions.loadStalls);
    const setEditStallId = useStoreActions((actions) => actions.setEditStallId);
    const stalls = useStoreState((state) => state.stalls);

    const {keycloak} = useKeycloak()
    let history = useHistory();
    const glookup = getCurrentMarketDayBoundary();

    useEffect(() => loadStalls(keycloak.token), [loadStalls, keycloak.token]);

    function isAllowedToEdit(stall) {
        return (keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.preferred_username === stall.owner)
            || isAdmin(keycloak);
    }

    function getDeleteButton(stall) {
        return isAllowedToEdit(stall) && <Icon
            title ={"Stand löschen"}
            size={"large"}
            name='trash alternate outline'
            link onClick={async () => {
            await stallApi.deleteStall(stall.id, keycloak.token);
            loadStalls(keycloak.token);
        }}/>;
    }

    function getEditButton(stall) {
        return <>
            {isAllowedToEdit(stall) && <Icon
                title ={"Beschreibung ändern"}
                size={"large"}
                name='edit outline'
                link onClick={() => {
                setEditStallId(stall.id);
                history.push("newStall/" + stall.id)
            }}/>}
        </>;
    }

    function getPauseButton(stall) {
        return <>
            {isAllowedToEdit(stall) && <Icon
                size={"large"}
                name={!stall.paused ? 'pause circle outline' : 'play circle outline'}
                title={!stall.paused ? 'Stand pausieren (ausblenden)' : 'Stand aktivieren (einblenden)'}
                link onClick={async () => {
                await stallApi.updateStallPause(stall.id, !stall.paused, keycloak.token);
                loadStalls(keycloak.token);
            }}/>}
        </>;
    }

    function renderDescription(stall) {
        return <>            {stall.description.split('\n').map((item, i) => <Fragment>{item}<br/></Fragment>)}</>;
    }

    const markers = stalls
        .filter(stall => isAllowedToEdit(stall) || stall.paused === false)
        .filter(stall => glookup.hasContainers({type: "Point", coordinates: [stall.lon, stall.lat]}))
        .map(stall => <Marker
            icon={stall.paused ? PausedIcon : DefaultIcon}
        key={stall.id}
        position={[stall.lat, stall.lon]}
        draggable={isAllowedToEdit(stall)}
        eventHandlers={{
            async dragend(event) {
                await stallApi.updateStallPosition(stall.id, event.target.getLatLng().lat, event.target.getLatLng().lng, keycloak.token)
            }
        }}>
        <Popup key={stall.id +"-popup"} minWidth={250}>
            {getEditButton(stall)}
            {getPauseButton(stall)}
            {getDeleteButton(stall)}
            {isAllowedToEdit(stall) && <Divider />}
            {renderDescription(stall)}
        </Popup>
    </Marker>);

    function getCenter() {
        const lastKnownMapCenter = JSON.parse(localStorage.getItem('lastKnownMapCenter'));
        if (lastKnownMapCenter) {
            return [lastKnownMapCenter.lat, lastKnownMapCenter.lng];
        }

        return [53.67667275520219, 9.90750578203773];
    }

    function getZoom() {
        const lastKnownZoomLevel = JSON.parse(localStorage.getItem('lastKnownZoomLevel'));
        if (lastKnownZoomLevel) {
            return lastKnownZoomLevel;
        }

        return 13;
    }

    const content = (
        <Fragment>
            <Header textAlign='center' className={"map-banner"}>
                {JSON.parse(localStorage.getItem('mapBannerText'))}
            </Header>
            <MapContainer center={getCenter()} zoom={getZoom()} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers}
                <LocationStorage/>
            </MapContainer>
        </Fragment>

    )
    return content;

};

function LocationStorage() {
    const map = useMapEvents({
        moveend(e) {
            let latLng = map.getCenter();
            localStorage.setItem('lastKnownMapCenter', JSON.stringify(latLng));
            localStorage.setItem('lastKnownZoomLevel', JSON.stringify(map.getZoom()));
        },
    })
    return null;
}

export default StandMap