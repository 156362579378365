export const marketDays = [
    // {
    //     id: "UETERSEN",
    //     name: "Flohmarkttag Uetersen",
    //     center:  {lat: "53.69286477654377", lng: "9.663959491234198", zoomLevel: 15},
    //     bannerText: "In Uetersen am 03.10.2023 von 10 - 16 Uhr",
    //     contactEmail: "strassenflohmarkt-uetersen@web.de",
    //     menu: "Uetersen (03.10.2023)",
    //     url: "/uetersen"
    // },
    // {
    //     id: "MUERWIK",
    //     name: "Flohmarkttag Mürwik",
    //     center:  {lat: "54.8114", lng: "9.4785", zoomLevel: 16},
    //     bannerText: "In Flensburg Mürwik am 28.07.2024 von 10 - 16 Uhr",
    //     contactEmail: "flohmarkt.flensburgmuerwik@gmail.com",
    //     menu: "Mürwik (28.07.2024)",
    //     url: "/muerwik"
    // },
    /*    {
            id: "QUICKBORN",
            name: "Flohmarkttag Quickborn",
            center:  {lat: "53.73697", lng: "9.90661", zoomLevel: 13},
            bannerText: "In Quickborn am 08.09.2024 von 10 - 16 Uhr",
            contactEmail: "quickborn@flohmarkttag.de",
            menu: "Quickborn (08.09.2024)",
            url: "/quickborn"
        },*/
    /*{
        id: "NORDERSTEDT",
        name: "Flohmarkttag Norderstedt",
        center: {lat: "53.6933", lng: "10.0281", zoomLevel: 14},
        bannerText: "In Norderstedt am 14.09.2024 von 11 - 16 Uhr",
        contactEmail: "simone_bruene@yahoo.com",
        menu: "Norderstedt (14.09.2024)",
        url: "/norderstedt"
    },
    {
        id: "ELLERBEK",
        name: "Flohmarkttag Ellerbek",
        center: {lat: "53.6485", lng: "9.8925", zoomLevel: 13},
        bannerText: "In Ellerbek am 15.09.2024 von 10 - 16 Uhr",
        contactEmail: "flohmarkt.ellerbek@gmail.com",
        menu: "Ellerbek (15.09.2024)",
        url: "/ellerbek"
    },*/
    {
        id: "BOENNINGSTEDT",
        name: "Flohmarkttag Bönningstedt",
        center: {lat: "53.66403", lng: "9.90552", zoomLevel: 13},
        bannerText: "In Bönningstedt am 29.09.2024 von 10 - 16 Uhr",
        contactEmail: "boenningstedt@flohmarkttag.de",
        menu: "Bönningstedt (29.09.2024)",
        url: "/boenningstedt"
    },
    // {
    //     id: "ELLERAU",
    //     name: "Flohmarkttag Ellerau",
    //     center:  {lat: "53.7523", lng: "9.9242", zoomLevel: 15},
    //     bannerText: "In Ellerau am 26.05.2024 von 10 - 16 Uhr",
    //     contactEmail: "s.pancke@googlemail.com",
    //     menu: "Ellerau (26.05.2024)",
    //     url: "/ellerau"
    // },
]

export const getMarketDay = (marketDayId) => {
    console.log("getMarketDay " + marketDayId)
    return marketDays.filter(marketDay => marketDay.id === marketDayId)[0];
}

export const getMarketDayBoundary = (marketDayId) => {
    switch (marketDayId) {
        case "NORDERSTEDT":
            return require("./geojson/norderstedt.json");

        case "ELLERBEK":
            return require("./geojson/ellerbek.json");

        case "BOENNINGSTEDT":
            return require("./geojson/boenningstedt.json");
    }
}
