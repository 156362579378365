import React from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {NavLink, withRouter} from 'react-router-dom'
import {Container, Dropdown, Icon, Menu} from 'semantic-ui-react'
import "./Navbar.css"
import {setCity} from "../../cities";
import {marketDays} from "../../marketDays";

function Navbar(props) {

    const {keycloak} = useKeycloak()

    const handleLogInOut = () => {
        if (keycloak.authenticated) {
            props.history.push('/')
            keycloak.logout()
        } else {
            keycloak.login()
        }
    }

    const checkAuthenticated = () => {
        if (!keycloak.authenticated) {
            handleLogInOut()
        }
    }

    const getLogInOutText = () => {
        return keycloak.authenticated ? "Abmelden" : "Anmelden"
    }

    return (
        <React.Fragment>
            <Menu className="menu-desktop" stackable>
                <Container>
                    <Menu.Item header as={NavLink} exact to="/home" style={{
                        "font-family": "Lobster",
                        "color": "limegreen",
                        "font-size": "x-large",
                        "padding-left": "1rem",
                        "padding-top": "0.2rem",
                        "padding-bottom": "0.4rem"
                    }}>Flohmarkttag</Menu.Item>
                    <Menu.Item as={NavLink} exact to="/howto">Wie geht das?</Menu.Item>
                    {marketDays.map(marketDay => {
                        return <Menu.Item onClick={() => {
                            setCity(marketDay.id);
                            window.location.replace("https://flohmarkttag.de" + marketDay.url);
                        }}>{marketDay.menu}</Menu.Item>
                    })}
                    <Menu.Item as={NavLink} exact to="/newStall/new" onClick={checkAuthenticated}>Stand
                        eintragen</Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item as={NavLink} exact to="/login"
                                   onClick={handleLogInOut}>{getLogInOutText()}</Menu.Item>
                    </Menu.Menu>
                </Container>
            </Menu>
            <Menu className="menu-mobile">
                <Container>
                    <Dropdown item icon='bars' simple>
                        <Dropdown.Menu>
                            <Dropdown.Item as={NavLink} exact to="/howto">Wie geht das?</Dropdown.Item>
                            <Dropdown.Item>
                                <Icon name='dropdown'/>
                                <span className='text'>Wo?</span>
                                <Dropdown.Menu>
                                    {marketDays.map(marketDay => {
                                        return <Dropdown.Item onClick={() => {
                                            setCity(marketDay.id);
                                            window.location.replace("https://flohmarkttag.de" + marketDay.url);
                                        }}>{marketDay.menu}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown.Item>
                            <Dropdown.Item as={NavLink} exact to="/newStall/new" onClick={checkAuthenticated}>Eigenen
                                Stand eintragen</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item as={NavLink} exact to="/login"
                                           onClick={handleLogInOut}>{getLogInOutText()}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item header as={NavLink} exact to="/home" style={{
                        "font-family": "Lobster",
                        "color": "limegreen",
                        "font-size": "x-large",
                        "padding-left": "1rem",
                        "padding-top": "0.2rem",
                        "padding-bottom": "0.4rem"
                    }}>Flohmarkttag</Menu.Item>
                </Container>
            </Menu>
        </React.Fragment>
    )
}

export default withRouter(Navbar)
