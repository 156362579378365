import React, {useEffect} from 'react'
import {Container} from 'semantic-ui-react'
import StandMap from "./StandMap";
import {useStoreState} from "easy-peasy";

const Home = () => {
useStoreState(state => state.stalls)
    useEffect(() => {
        document.title = "Flohmarkttag - " + JSON.parse(localStorage.getItem('mapBannerText')) + " - Mache mit und trage deinen Stand hier ein!";
    }, []);
    return (
        <Container>
            <StandMap/>
        </Container>
    );
}

export default Home