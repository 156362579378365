import {action, createStore, thunk} from "easy-peasy";
import {stallApi} from "./components/misc/StallApi";
import KeycloakInstance from 'keycloak-js';

const store = createStore({
    stalls: [],
    setStalls: action((state, payload) => {
        state.stalls = payload;
    }),
    loadStalls: thunk(async (actions, token) => {
        const stalls  = await stallApi.getStalls(token);
        actions.setStalls(stalls.data);
    }),
    editStallId: undefined,
    setEditStallId: action((state, payload) => {
        state.editStallId = payload;
    }),
});

export default store;